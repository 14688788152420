import React from "react";
import { Link } from "react-router-dom";
import { StyledButton } from "./Checkout.elements";

const CheckoutSteps = ({ shipping, confirmOrder, payment }) => {
  return (
    <div className='checkout-progress d-flex justify-content-center mt-5'>
      {shipping ? (
        <Link to='/shipping' className='float-right' style={{ marginRight: '4px' }} >
          <div className='triangle2-active'></div>
          <div className='step active-step' style={{ cursor: 'pointer' }}> Shipping </div>
          <div className='triangle-active'></div>
        </Link>
      ) : (
        <StyledButton disabled>
          <div className='triangle2-incomplete'></div>
          <div className='step incomplete' > Shipping </div>
          <div className='triangle-incomplete'></div>
        </StyledButton>
      )}

      {confirmOrder ? (
        <Link to='/ordered/confirm' className='float-right' style={{ marginRight: '4px' }} >
          <div className='triangle2-active'></div>
          <div className='step active-step' style={{ cursor: 'pointer' }}> Confirm Order </div>
          <div className='triangle-active'></div>
        </Link>
      ) : (
        <button
          style={{ backgroundColor: "transparent", border: "none", marginRight: '4px' }}
          disabled
        >
          <div className='triangle2-incomplete'></div>
          <div className='step incomplete' > Confirm Order</div>
          <div className='triangle-incomplete'></div>
        </button>
      )}
      {payment ? (
        <Link to='/payment' className='float-right'>
          <div className='triangle2-active'></div>
          <div className='step active-step' style={{ cursor: 'pointer' }}> Payment </div>
          <div className='triangle-active'></div>
        </Link>
      ) : (
        <button
          style={{ backgroundColor: "transparent", border: "none" }}
          disabled
        >
          <div className='triangle2-incomplete'></div>
          <div className='step incomplete' > Payment</div>
          <div className='triangle-incomplete'></div>
        </button>
      )}
    </div>
  );
};

export default CheckoutSteps;
