import React from "react";
import { Link } from "react-router-dom";

const Product = ({ product, col }) => {
  return (
    <>
      <div
        key={product?._id}
        className={`col-12 col-sm-6 col-md-6 col-lg-${col} col-xl-${
          col === 6 ? 4 : 3
        } my-3`}
        style={{ maxHeight: "400px" }}
      >
        <div className='card p-3 rounded'>
          <img
            className='card-img-top mx-auto'
            src={product?.images[0] && product?.images[0].url}
          />
          <div className='card-body d-flex flex-column'>
            <h5
              className='card-title'
              style={{ maxHeight: "50px", overflow: "hidden" }}
            >
              <Link to={`/product/${product?._id}`}>{product?.name}</Link>
            </h5>
            <div className='ratings mt-auto'>
              <div className='rating-outer'>
                <div
                  className='rating-inner'
                  style={{
                    width: `${(product?.rating / 5) * 100}%`,
                  }}
                ></div>
              </div>
              <span id='no_of_reviews'>{product?.numOfReviews}</span>
            </div>
            <p className='card-text'>{product?.price} ETB </p>
            <Link
              to={`/product/${product?._id}`}
              id='view_btn'
              className='btn btn-block'
            >
              View Details
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
