import React, { Fragment, useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import MetaData from "./layout/MetaData";
// import Slider from "rc-slider";
import Slider from "react-slick";
import "rc-slider/assets/index.css";

import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../actions/productActions";
import Product from "../components/product/Product";
import Loader from "./layout/Loader";
import { useAlert } from "react-alert";
import { useLocation, useParams } from "react-router";
import RelatedProducts from "./product/RelatedProducts";
import RCSlider from "./RCSlider";
import { Form } from "react-bootstrap";
import upward from "../assets/upward.png";

const Home = ({ match, authCheck }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [price, setPrice] = useState([1, 10000]);
  const [category, setCategory] = useState("");
  const [rating, setRating] = useState(0);

  const [localCount, setLocalCount] = useState(0);
  // let localCount = [];

  const keyword = match?.params?.keyword;
  const pathname = match?.path;

  const categories = [
    "Networking",
    "Desktops",
    "Laptops",
    "Accessories",
    "POS",
    "Cameras",
  ];

  const alert = useAlert();
  const dispatch = useDispatch();

  const {
    loading,
    products,
    productsCategories,
    error,
    productsCount,
    resPerPage,
    filteredProductsCount,
  } = useSelector((state) => state.products);
  useEffect(() => {
    if (error) {
      return alert.error(error);
    }
    dispatch(
      getProducts(pathname, keyword, currentPage, price, category, rating)
    );
  }, [dispatch, alert, error, keyword, currentPage, price, category, rating]);

  function setCurrentPageNo(pageNumber) {
    setCurrentPage(pageNumber);
  }

  let count = productsCount;
  if (keyword) {
    count = filteredProductsCount;
  }
  console.log({ keyword });

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, background: "#000000" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, background: "#000000" }}
        onClick={onClick}
      />
    );
  }

  let settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          nextArrow: <div />,
          prevArrow: <div />,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <div />,
          prevArrow: <div />,
        },
      },
    ],
  };

  return (
    <Fragment style={{ margin: "50px 0px 0px 0px" }}>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title={`Buy best products online.`} />
          <div className='pt-10 mt-10'>
            {pathname === "/" && <h1 id='products_heading'>Latest Products</h1>}
            {products && pathname !== "/" && products.length > 0 && (
              <h4 id='products_heading' className='reultPad'>
                {"Results for "}
                {keyword ? "'" + keyword + "'" + " keyword" : ""}
                {price &&
                  ", between " + price[0] + "ETB and " + price[1] + "ETB"}
                {category !== "" && ", under " + category + " category"}
                {rating ? rating !== 0 && ", above " + rating + " rating" : ""}
              </h4>
            )}
          </div>

          <section id='products' className='container mt-10'>
            <div className='row'>
              {keyword ? (
                <Fragment>
                  <div className='col-12 col-md-3 mt-1 mt-md-18 '>
                    <div className='px-0 px-md-2 px-lg-5 '>
                      <RCSlider
                        products={products && products}
                        setPrice={setPrice}
                        price={price}
                      />
                      <hr className='mt-2 my-md-5 ' />
                      <div className='row mt-0 mt-md-5'>
                        <div className='col-6 col-md-12'>
                          <h4 className='mb-3'>Categories</h4>
                          <ul className='pl-0'>
                            {categories.map((cat) => {
                              return (
                                <li
                                  key={cat}
                                  style={{
                                    cursor: "pointer",
                                    listStyleType: "none",
                                    fontWeight: category === cat && 800,
                                  }}
                                  onClick={() =>
                                    !(category === cat)
                                      ? setCategory(cat)
                                      : setCategory("")
                                  }
                                  className='row'
                                >
                                  <Form.Check
                                    type='checkbox'
                                    checked={category === cat && true}
                                  />
                                  <div>{cat}</div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className='mt-0 mt-md-5 col-6 col-md-12'>
                          <h4 className='mb-3'>Ratings</h4>
                          <div className='row'>
                            <img
                              width='10px'
                              style={{
                                maxHeight:
                                  rating === 0 || rating === 1
                                    ? "115px"
                                    : rating === 2
                                    ? "92px"
                                    : rating === 3
                                    ? "68px"
                                    : rating === 4
                                    ? "44px"
                                    : rating === 5
                                    ? "20px"
                                    : "",
                              }}
                              src={upward}
                            />
                            <ul className='pl-0'>
                              {[5, 4, 3, 2, 1].map((star) => (
                                <li
                                  key={star}
                                  style={{
                                    cursor: "pointer",
                                    listStyleType: "none",
                                  }}
                                  onClick={() =>
                                    !(rating === star)
                                      ? setRating(star)
                                      : setRating(0)
                                  }
                                >
                                  <div className='rating-outer'>
                                    <div
                                      className='rating-inner'
                                      style={{
                                        width: `${star * 20}%`,
                                      }}
                                    ></div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col col-md-9 mt-10'>
                    <div className='row '>
                      {products && products.length === 0 && (
                        <h3 id='products_heading'>
                          {" "}
                          No results found for "{keyword && keyword}"
                          {price &&
                            ", between " +
                              price[0] +
                              "ETB and " +
                              price[1] +
                              "ETB"}
                          {category !== "" &&
                            ", under " + category + " category"}
                          {rating
                            ? rating !== 0 && ", above " + rating + " rating"
                            : ""}
                        </h3>
                      )}
                      {products &&
                        products.map((product) => {
                          return (
                            <Product
                              key={product._id}
                              product={product}
                              col={6}
                            />
                          );
                        })}
                    </div>
                  </div>
                </Fragment>
              ) : (
                products &&
                products.map((product) => {
                  return (
                    <Product key={product._id} product={product} col={4} />
                  );
                })
              )}
            </div>
          </section>
          {resPerPage <= productsCount && pathname === "/" && (
            <div className='d-flex justify-content-center'>
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={resPerPage}
                totalItemsCount={productsCount}
                onChange={setCurrentPageNo}
                nextPageText={"Next"}
                prevPageText={"Prev"}
                firstPageText={"First"}
                lastPageText={"Last"}
                itemClss='page-item'
                linkClass='page-link'
              />
            </div>
          )}
          {pathname === "/" && (
            <>
              <div className='mt-4'>
                <h2> Networking Products </h2>
                <Slider {...settings}>
                  {productsCategories &&
                    productsCategories.length > 0 &&
                    productsCategories.map((productCategory) => {
                      if (productCategory?.category !== "Networking") return;
                      return (
                        <div>
                          <RelatedProducts
                            key={productCategory._id}
                            product={productCategory}
                            col={12}
                          />
                        </div>
                      );
                    })}
                </Slider>
              </div>
              <div className='mt-4'>
                <h2> Laptops </h2>
                <Slider {...settings}>
                  {productsCategories &&
                    productsCategories.length > 0 &&
                    productsCategories.map((productCategory) => {
                      if (productCategory?.category !== "Laptops") return;
                      return (
                        <div>
                          <RelatedProducts
                            key={productCategory._id}
                            product={productCategory}
                            col={12}
                          />
                        </div>
                      );
                    })}
                </Slider>
              </div>
              <div className='mt-4'>
                <h2> Accessories </h2>
                <Slider {...settings}>
                  {productsCategories &&
                    productsCategories.length > 0 &&
                    productsCategories.map((productCategory) => {
                      if (productCategory?.category !== "Accessories") return;
                      return (
                        <div>
                          <RelatedProducts
                            key={productCategory._id}
                            product={productCategory}
                            col={12}
                          />
                        </div>
                      );
                    })}
                </Slider>
              </div>
              <div className='mt-4'>
                <h2> POS products </h2>
                <Slider {...settings}>
                  {productsCategories &&
                    productsCategories.length > 0 &&
                    productsCategories.map((productCategory) => {
                      if (productCategory?.category !== "POS") return;

                      return (
                        <div>
                          <RelatedProducts
                            key={productCategory._id}
                            product={productCategory}
                            col={12}
                          />
                        </div>
                      );
                    })}
                </Slider>
              </div>
            </>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Home;
