import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import MetaData from "../layout/MetaData";
import CheckoutSteps from "./CheckoutSteps";
import { useSelector } from "react-redux";
import { saveShippingInfo } from "../../actions/cartActions";

const ConfirmOrder = ({ history }) => {
  const { cartItems, shippingInfo } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.auth);

  // Calculate order prices
  const itemsPrice = cartItems.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );
  const shippingPrice = itemsPrice > 200 ? 0 : 25;
  const taxPrice = Number((0.05 * itemsPrice).toFixed(2));
  const totalPrice = (itemsPrice + shippingPrice + taxPrice).toFixed(2);

  const processToPayment = () => {
    const data = {
      itemsPrice: itemsPrice.toFixed(2),
      shippingPrice,
      taxPrice,
      totalPrice,
    };

    sessionStorage.setItem("orderInfo", JSON.stringify(data));
    history.push("/payment");
  };

  return (
    <Fragment>
      <MetaData title={"Confirm Order"} />
      <CheckoutSteps shipping confirmOrder />
      <div className='row d-flex justify-content-between'>
        <div className='col-12 col-lg-8 mt-5 order-confirm'>
          <h4 className='mb-3'>Shipping Info</h4>
          <p>
            <b>Name:</b> {user && user.name}
          </p>
          <p>
            <b>Phone:</b> {shippingInfo.phoneNo}
          </p>
          <p className='mb-4'>
            <b>Address: </b>
            {`${shippingInfo.address}, ${shippingInfo.city}, ${shippingInfo.postalCode}, ${shippingInfo.country}`}
          </p>

          <hr />
          <h4 className='mt-4'>Your Cart Items:</h4>
          {cartItems.map((item) => {
            return (
              <Fragment>
                <hr />
                <div className='cart-item my-1' key={item.product}>
                  <div className='row'>
                    <div className='col-4 col-lg-2'>
                      <img
                        src={item.image}
                        alt='Laptop'
                        height='45'
                        width='65'
                      />
                    </div>

                    <div className='col-5 col-lg-6'>
                      <Link to={`/product/${item.product}`}>
                        {item.name} ETB
                      </Link>
                    </div>

                    <div className='col-sm-10 col-lg-4 mt-4 mt-lg-0'>
                      <p>
                        {item.quantity} x {item.price} =
                        <b> {(item.quantity * item.price).toFixed(2)} ETB </b>
                      </p>
                    </div>
                  </div>
                </div>
                <hr />
              </Fragment>
            );
          })}
        </div>

        <div className='col-12 col-lg-3 my-4'>
          <div id='order_summary'>
            <h4>Order Summary</h4>
            <hr />
            <p>
              Subtotal:
              <span className='order-summary-values'>{itemsPrice} ETB </span>
            </p>
            <p>
              Shipping:
              <span className='order-summary-values'>{shippingPrice} ETB </span>
            </p>
            <p>
              Tax: <span className='order-summary-values'>{taxPrice} ETB </span>
            </p>
            <hr />
            <p>
              Total:
              <span className='order-summary-values'>{totalPrice} ETB </span>
            </p>
            <hr />
            <button
              id='checkout_btn'
              className='btn btn-primary btn-block'
              onClick={processToPayment}
            >
              Proceed to Payment
            </button>
            <a
              id='checkout_btn'
              className='btn btn-primary btn-block'
              href={`tel:+251921602069`}
            >
              Call us
            </a>
            <h6 style={{ padding: "8px 0px 0px 0px" }} className='text-center'>
              0921602069
            </h6>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfirmOrder;
