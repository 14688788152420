import React, { Fragment, useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import Loader from "../layout/Loader";
import MetaData from "../layout/MetaData";
import { useAlert } from "react-alert";
import {
  getProductDetails,
  clearErrors,
  newReview,
} from "../../actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import { addItemsToCart } from "../../actions/cartActions";
import { NEW_REVIEW_RESET } from "../../constants/productConstants";
import ListReviews from "../review/ListReviews";
import "./product.css";
import Slider from "react-slick";
import Product from "./Product";
import RelatedProducts from "./RelatedProducts";

const ProductDetails = ({ match }) => {
  const [quantity, setQuantity] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const dispatch = useDispatch();
  const alert = useAlert();

  const { loading, error, product, relatedProducts } = useSelector(
    (state) => state.productDetails
  );

  const { user } = useSelector((state) => state.auth);
  const { error: reviewError, success } = useSelector(
    (state) => state.newReview
  );

  useEffect(() => {
    dispatch(getProductDetails(match.params.id));

    if (error) {
      alert.error(error.message);
      dispatch(clearErrors());
    }
    if (reviewError) {
      alert.error(error.message);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Review posted successfully");
      dispatch({ type: NEW_REVIEW_RESET });
    }
  }, [dispatch, alert, error, reviewError, match.params.id, success]);

  const increaseQty = () => {
    const count = document.querySelector(".count");
    if (count.valueAsNumber >= product.stock) return;

    const qty = count.valueAsNumber + 1;
    setQuantity(qty);
  };

  const decreaseQty = () => {
    const count = document.querySelector(".count");
    if (count.valueAsNumber <= 1) return;

    const qty = count.valueAsNumber - 1;
    setQuantity(qty);
  };

  const addToCart = () => {
    dispatch(addItemsToCart(match.params.id, quantity));
    alert.success("Item Added to Cart.");
  };

  function setUserRatings() {
    const stars = document.querySelectorAll(".star");
    stars.forEach((star, index) => {
      star.starValue = index + 1;

      ["click", "mouseover", "mouseout"].forEach(function (e) {
        star.addEventListener(e, showRatings);
      });
    });

    function showRatings(e) {
      stars.forEach((star, index) => {
        if (e.type === "click") {
          if (index < this.starValue) {
            star.classList.add("orange");
            setRating(this.starValue);
          } else {
            star.classList.remove("orange");
          }
        }
        if (e.type === "mouseover") {
          if (index < this.starValue) {
            star.classList.add("yellow");
          } else {
            star.classList.remove("yellow");
          }
        }
        if (e.type === "mouseout") {
          star.classList.remove("yellow");
        }
      });
    }
  }

  const reviewHandler = () => {
    const formData = new FormData();

    formData.set("rating", rating);
    formData.set("comment", comment);
    formData.set("productId", match.params.id);

    dispatch(newReview(formData));
  };

  let settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title={product?.name} />
          <div className='row f-flex justify-content-around'>
            <div
              className='col-12 col-lg-5 img-fluid mb-10 '
              id='product_image'
            >
              <Carousel pause='hover'>
                {product.images &&
                  product.images.map((image) => {
                    return (
                      <Carousel.Item key={image.public_id}>
                        <img
                          className='d-block w-100'
                          src={image.url}
                          alt={product.title}
                        />
                        {/* <img
                          src='https://i5.walmartimages.com/asr/1223a935-2a61-480a-95a1-21904ff8986c_1.17fa3d7870e3d9b1248da7b1144787f5.jpeg?odnWidth=undefined&odnHeight=undefined&odnBg=ffffff'
                          alt='sdf'
                          height='500'
                          width='500'
                        /> */}
                      </Carousel.Item>
                    );
                  })}
              </Carousel>
              {/* <img
                src='https://i5.walmartimages.com/asr/1223a935-2a61-480a-95a1-21904ff8986c_1.17fa3d7870e3d9b1248da7b1144787f5.jpeg?odnWidth=undefined&odnHeight=undefined&odnBg=ffffff'
                alt='sdf'
                height='500'
                width='500'
              /> */}
            </div>

            <div className='col-12 col-lg-5 mt-5'>
              <h3>{product?.name}</h3>
              {/* <p id='product_id'>Product # {product._id}</p> */}

              <hr />

              <div className='rating-outer'>
                <div
                  className='rating-inner'
                  style={{
                    width: `${(product?.rating / 5) * 100}%`,
                  }}
                ></div>
              </div>
              <span id='no_of_reviews'>({product?.numOfReviews}) Reviews </span>
              <hr />

              <p id='product_price'>{product?.price} ETB </p>
              <div className='stockCounter d-inline'>
                <span className='btn btn-danger minus' onClick={decreaseQty}>
                  -
                </span>

                <input
                  type='number'
                  className='form-control count d-inline'
                  value={quantity}
                  readOnly
                />

                <span className='btn btn-primary plus' onClick={increaseQty}>
                  +
                </span>
              </div>
              <button
                type='button'
                id='cart_btn'
                className='btn btn-primary d-inline ml-4'
                disabled={product.stock === 0}
                onClick={addToCart}
              >
                Add to Cart
              </button>

              <hr />

              <p>
                Status:
                <span
                  id='stock_status'
                  className={product.stock > 0 ? "greenColor" : "redColor"}
                >
                  {product?.stock > 0 ? "In Stock" : "Out of Stock"}
                </span>
              </p>

              <hr />

              <h4 className='mt-2'>Description:</h4>
              <p>{product?.description} </p>
              <hr />
              {/* <p id='product_seller mb-3'>
                Sold by: <strong>{product?.seller}</strong>
              </p> */}
              {user ? (
                <button
                  id='review_btn'
                  type='button'
                  className='btn btn-primary mt-4'
                  data-toggle='modal'
                  data-target='#ratingModal'
                  onClick={setUserRatings}
                >
                  Submit Your Review
                </button>
              ) : (
                <div className='alert alert danger mt-5' type='alert'>
                  Login to post your review.
                </div>
              )}

              <div className='row mt-2 mb-5'>
                <div className='rating w-50'>
                  <div
                    className='modal fade'
                    id='ratingModal'
                    tabIndex='-1'
                    role='dialog'
                    aria-labelledby='ratingModalLabel'
                    aria-hidden='true'
                  >
                    <div className='modal-dialog' role='document'>
                      <div className='modal-content'>
                        <div className='modal-header'>
                          <h5 className='modal-title' id='ratingModalLabel'>
                            Submit Review
                          </h5>
                          <button
                            type='button'
                            className='close'
                            data-dismiss='modal'
                            aria-label='Close'
                          >
                            <span aria-hidden='true'>&times;</span>
                          </button>
                        </div>
                        <div className='modal-body'>
                          <ul className='stars'>
                            <li className='star'>
                              <i className='fa fa-star'></i>
                            </li>
                            <li className='star'>
                              <i className='fa fa-star'></i>
                            </li>
                            <li className='star'>
                              <i className='fa fa-star'></i>
                            </li>
                            <li className='star'>
                              <i className='fa fa-star'></i>
                            </li>
                            <li className='star'>
                              <i className='fa fa-star'></i>
                            </li>
                          </ul>

                          <textarea
                            name='review'
                            id='review'
                            className='form-control mt-3'
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                          ></textarea>

                          <button
                            className='btn my-3 float-right review-btn px-4 text-white'
                            onClick={reviewHandler}
                            data-dismiss='modal'
                            aria-label='Close'
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {product.reviews && product.reviews.length > 0 && (
              <ListReviews reviews={product.reviews} />
            )}
          </div>

          <div>
            <h2> Related Products </h2>
            <Slider {...settings}>
              {relatedProducts &&
                relatedProducts.length > 0 &&
                relatedProducts.map((relatedProduct) => {
                  if (relatedProduct?._id === product?._id) return;
                  return (
                    <div>
                      <RelatedProducts
                        key={relatedProduct._id}
                        product={relatedProduct}
                        col={12}
                      />
                    </div>
                  );
                })}
            </Slider>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ProductDetails;
