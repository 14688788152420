import React from "react";
import Slider from "rc-slider";

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const RCSlider = ({ setPrice, price, products }) => {
  return (
    <div
      style={{
        marginTop: (products && products.length) === 0 ? "50px" : "0px",
      }}
    >
      <Range
        marks={{
          1: `1 ETB`,
          10000: `10000 ETB`,
        }}
        min={1}
        max={10000}
        defaultValue={[1, 10000]}
        tipFormatter={(value) => `${value} ETB`}
        tipProps={{
          placement: "top",
          visible: true,
        }}
        value={price}
        onChange={(price) => setPrice(price)}
      />
    </div>
  );
};

export default RCSlider;
