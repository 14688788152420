import React, { Fragment } from "react";
import { Link, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { logout } from "../../actions/userActions";
import Search from "./Search";
import logo from "../../assets/logo.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import "./Header.css";

const Header = () => {
  const alert = useAlert();
  const dispach = useDispatch();

  const { user, loading } = useSelector((state) => state.auth);
  const { cartItems } = useSelector((state) => state.cart);
  const logoutHandler = () => {
    dispach(logout());
    alert.success("Logged out successfully.");
  };
  function split_at_index(value, index) {
    return value.substring(0, index) + "," + value.substring(index);
  }

  //
  return (
    <Fragment>
      <nav className='navbar fixed-top'>
        <div className='d-flex' id='cartProfilee'>
          <div
            className='navbar-brand nav-logoo flex-grow-1'
            style={{ cursor: "pointer" }}
          >
            <Link to='/'>
              <img
                src={logo}
                width='80px'
                style={{ height: "48px" }}
                className='companyLogo'
              />
            </Link>
          </div>
          <div className='cartProfileBelow float-right'>
            <Link
              to='/cart'
              style={{ textDecoration: "none" }}
              className='text-center '
            >
              <span id='cart' className='ml-1 mr-1'>
                Cart
              </span>
              <span className='mr-3' id='cart_count'>
                {cartItems.length}
              </span>
            </Link>
            {user ? (
              <div className='dropdown d-inline loginButton p-0 m-0'>
                <button
                  style={{ backgroundColor: "transparent" }}
                  to='#!'
                  className='btn dropdown-toggle text-white mr-4'
                  type='button'
                  id='dropDownMenuButton'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <figure className='avatar avatar-nav'>
                    <img
                      src={user?.avatar && user?.avatar.url}
                      // alt={user && user?.name}
                      className='rounded-circle'
                    />
                  </figure>
                  <span>
                    {" "}
                    {user &&
                      user?.name &&
                      user?.name.split(" ")[0] &&
                      split_at_index(user?.name.split(" ")[0], 9).split(",")[0]}
                  </span>
                </button>
                <div
                  className='dropdown-menu'
                  aria-labelledby=' dropDownMenuButton'
                >
                  {user && user?.role === "admin" && (
                    <Link className='dropdown-item' to='/dashboard'>
                      Dashboard
                    </Link>
                  )}
                  {user && user?.role !== "admin" && (
                    <Link className='dropdown-item' to='/orders/me'>
                      Orders
                    </Link>
                  )}

                  <Link className='dropdown-item' to='/me'>
                    Profile
                  </Link>
                  <Link
                    className='dropdown-item text-danger'
                    to='/'
                    onClick={logoutHandler}
                  >
                    Logout
                  </Link>
                </div>
              </div>
            ) : (
              !loading && (
                <Link to='/login'>
                  <button className='btn' id='login_btn'>
                    Login
                  </button>
                </Link>
              )
            )}
          </div>
        </div>

        <div className='col-12 col-md-6 mt-2 mt-md-0'>
          <Route render={({ history }) => <Search history={history} />} />
        </div>

        <div
          className='col-12 col-md-3 mt-4 mt-md-0 text-center cartProfile'
          style={{ flexGrow: "1" }}
        >
          <Link to='/cart' style={{ textDecoration: "none" }}>
            <span id='cart' className='ml-1 mr-1'>
              Cart
            </span>
            <span className='mr-3' id='cart_count'>
              {cartItems.length}
            </span>
          </Link>
          {user ? (
            <div className='ml-4 dropdown d-inline'>
              <button
                style={{ backgroundColor: "transparent" }}
                to='#!'
                className='btn dropdown-toggle text-white mr-4'
                type='button'
                id='dropDownMenuButton'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                <figure className='avatar avatar-nav'>
                  <img
                    src={user?.avatar && user?.avatar.url}
                    alt={user && user?.name}
                    className='rounded-circle'
                  />
                </figure>
                <>
                  {user &&
                    user?.name &&
                    user?.name.split(" ")[0] &&
                    split_at_index(user?.name.split(" ")[0], 13).split(
                      ","
                    )[0]}{" "}
                </>
              </button>
              <div
                className='dropdown-menu'
                aria-labelledby=' dropDownMenuButton'
              >
                {user && user?.role === "admin" && (
                  <Link className='dropdown-item' to='/dashboard'>
                    Dashboard
                  </Link>
                )}
                {user && user?.role !== "admin" && (
                  <Link className='dropdown-item' to='/orders/me'>
                    Orders
                  </Link>
                )}

                <Link className='dropdown-item' to='/me'>
                  Profile
                </Link>
                <Link
                  className='dropdown-item text-danger'
                  to='/'
                  onClick={logoutHandler}
                >
                  Logout
                </Link>
              </div>
            </div>
          ) : (
            !loading && (
              <Link to='/login'>
                <button className='btn' id='login_btn'>
                  Login
                </button>
              </Link>
            )
          )}
        </div>
      </nav>
    </Fragment>
  );
};

export default Header;
