import React, { Fragment, useEffect } from "react";

import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

import MetaData from "../layout/MetaData";
import Loader from "../layout/Loader";

import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import {
  getAdminProducts,
  clearErrors,
  deleteProduct,
} from "../../actions/productActions";
import { DELETE_PRODUCT_RESET } from "../../constants/productConstants";
import { Button, CloseButton, OverlayTrigger, Popover } from "react-bootstrap";

const ProductsList = ({ history }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, error, products } = useSelector((state) => state.products);
  const {
    error: deleteError,
    isDeleted,
    loading: deleteLoading,
  } = useSelector((state) => state.product);
  useEffect(() => {
    dispatch(getAdminProducts());

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
    }
    if (isDeleted) {
      alert.success("Product deleted successfully");
      history.push("/admin/products");
      dispatch({ type: DELETE_PRODUCT_RESET });
    }
  }, [dispatch, alert, error, deleteError, isDeleted, history]);

  const setProducts = () => {
    const data = {
      columns: [
        {
          label: "Name",
          field: "name",
          sort: "asc",
        },
        {
          label: "Price",
          field: "price",
          sort: "asc",
        },

        {
          label: "Stock",
          field: "stock",
          sort: "asc",
        },
        {
          label: "Actions",
          field: "actions",
        },
      ],
      rows: [],
    };
    products.forEach((product) => {
      data.rows.push({
        // id: product._id,
        name: product.name,
        price: `${product.price} ETB`,
        // category: product?.category,
        stock: product.stock,
        actions: (
          <Fragment>
            <Link
              to={`/admin/product/${product._id}`}
              className='btn btn-primary py-1 px-2'
            >
              <i className='fa fa-pencil' />
            </Link>
            {!deleteLoading && (
              <OverlayTrigger
                trigger='click'
                placement='left'
                rootClose
                overlay={
                  <Popover id='popover-basic'>
                    <CloseButton
                      aria-label='Close'
                      className='icon-button'
                      onClick={() => document.body.click()}
                    />
                    <div
                      className='column'
                      style={{
                        padding: "10px",
                        fontSize: "20px",
                        background: "#232F3E",
                        color: "white",
                      }}
                    >
                      <div>Are you sure you want to delete?</div>
                      <div className='d-flex'>
                        <div className='flex-grow-1' />
                        <Button
                          className='mt-9 cursor-pointer'
                          variant='danger'
                          onClick={() => deleteProductHanlder(product?._id)}
                          style={{ cursor: "pointer" }}
                        >
                          Yes
                        </Button>
                      </div>
                    </div>
                  </Popover>
                }
              >
                <button className='btn btn-danger py-1 px-2 ml-2'>
                  {" "}
                  <i className='fa fa-trash' />
                </button>
              </OverlayTrigger>
            )}
          </Fragment>
        ),
      });
    });
    return data;
  };

  const deleteProductHanlder = (id) => {
    dispatch(deleteProduct(id));
  };

  return (
    <Fragment>
      <MetaData title={"All products"} />
      <div className='row'>
        <div className='col-12 col-md-2'>
          <Sidebar />
        </div>
        <div className='col-12 col-md-10'>
          <Fragment>
            <h1 className='my-5'> All Products </h1>
            {loading ? (
              <Loader />
            ) : (
              <Fragment>
                <MDBDataTable
                  data={setProducts()}
                  className='px-3'
                  bordered
                  striped
                  hover
                />
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductsList;
