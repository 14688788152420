import React, { Fragment } from "react";

const Footer = () => {
  return (
    <Fragment>
      <footer className='py-1'>
        <p className='text-center mt-1'>
          Wow-importer - 2021, All Rights Reserved
        </p>
      </footer>
    </Fragment>
  );
};

export default Footer;
