import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

import MetaData from "../layout/MetaData";
import Loader from "../layout/Loader";

import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import { allUsers, clearErrors, deleteUser } from "../../actions/userActions";
import { DELETE_USER_RESET } from "../../constants/userConstants";
import { Button, CloseButton, OverlayTrigger, Popover } from "react-bootstrap";

const UserLists = ({ history }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, error, users } = useSelector((state) => state.allUsers);
  const { isDeleted, loading: deleteLoading } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    dispatch(allUsers());

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (isDeleted) {
      alert.success("User deleted successfully");
      history.push("/admin/users");
      dispatch({ type: DELETE_USER_RESET });
    }
  }, [dispatch, alert, error, isDeleted, history]);

  const deleteUserHandler = (id) => {
    dispatch(deleteUser(id));
  };

  const setUsers = () => {
    const data = {
      columns: [
        // {
        //   label: "User ID",
        //   field: "id",
        //   sort: "asc",
        // },
        {
          label: "Name",
          field: "name",
          sort: "asc",
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
        },
        {
          label: "Role",
          field: "role",
          sort: "asc",
        },
        {
          label: "Actions",
          field: "actions",
        },
      ],
      rows: [],
    };
    users.forEach((user) => {
      data.rows.push({
        // id: user?._id,
        name: user?.name,
        email: user?.email,
        role: user?.role,
        actions: (
          <Fragment>
            <Link
              to={`/admin/user/${user._id}`}
              className='btn btn-primary py-1 px-2'
            >
              <i className='fa fa-pencil' />
            </Link>
            {!deleteLoading && (
              <OverlayTrigger
                trigger='click'
                placement='left'
                rootClose
                overlay={
                  <Popover id='popover-basic'>
                    <CloseButton
                      aria-label='Close'
                      className='icon-button'
                      onClick={() => document.body.click()}
                    />
                    <div
                      className='column'
                      style={{
                        padding: "10px",
                        fontSize: "20px",
                        background: "#232F3E",
                        color: "white",
                      }}
                    >
                      <div>Are you sure you want to delete?</div>
                      <div className='d-flex'>
                        <div className='flex-grow-1' />
                        <Button
                          className='mt-9 cursor-pointer'
                          variant='danger'
                          onClick={() => deleteUserHandler(user?._id)}
                          style={{ cursor: "pointer" }}
                        >
                          Yes
                        </Button>
                      </div>
                    </div>
                  </Popover>
                }
              >
                <button className='btn btn-danger py-1 px-2 ml-2'>
                  {" "}
                  <i className='fa fa-trash' />
                </button>
              </OverlayTrigger>
            )}
            {/* <button
              className='btn btn-danger py-1 px-2 ml-2'
              onClick={() => deleteUserHandler(user?._id)}
            >
              <i className='fa fa-trash' />
            </button> */}
          </Fragment>
        ),
      });
    });
    return data;
  };

  return (
    <Fragment>
      <MetaData title={"All Users"} />
      <div className='row'>
        <div className='col-12 col-md-2'>
          <Sidebar />
        </div>
        <div className='col-12 col-md-10'>
          <Fragment>
            <h1 className='my-5'> All Users </h1>
            {loading ? (
              <Loader />
            ) : (
              <Fragment>
                <MDBDataTable
                  data={setUsers()}
                  className='px-3'
                  bordered
                  striped
                  hover
                />
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

export default UserLists;
