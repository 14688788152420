import React, { Fragment, useState } from "react";
import { countries } from "countries-list";
import MetaData from "../layout/MetaData";
import CheckoutSteps from "./CheckoutSteps";
import { useDispatch, useSelector } from "react-redux";
import { saveShippingInfo } from "../../actions/cartActions";

const Shipping = ({ history }) => {
  const { shippingInfo } = useSelector((state) => state.cart);
  const countriesList = Object.values(countries);

  const [address, setAddress] = useState(shippingInfo.address);
  const [city, setCity] = useState(shippingInfo.city);
  const [postalCode, setPostalCode] = useState(shippingInfo.postalCode);
  const [phoneNo, setPhoneNo] = useState(shippingInfo.phoneNo);
  const [country, setCountry] = useState("Ethiopia");

  const dispacth = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();

    dispacth(saveShippingInfo({ address, city, phoneNo, postalCode, country }));
    history.push("/ordered/confirm");
  };

  return (
    <div style={{ padding: "0px 0px 0px 0px" }}>
      <MetaData title={"Shipping Info"} />
      <div>
        <CheckoutSteps shipping />
      </div>
      <div className='row wrapper'>
        <div className='col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 '>
          <form className='shadow-lg' onSubmit={submitHandler}>
            <h2 className='mb-3'>Shipping Info</h2>
            <div className='form-group'>
              <label htmlFor='address_field'>Address</label>
              <input
                type='text'
                id='address_field'
                className='form-control'
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              />
            </div>

            <div className='form-group'>
              <label htmlFor='city_field'>City</label>
              <input
                type='text'
                id='city_field'
                className='form-control'
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
              />
            </div>

            <div className='form-group'>
              <label htmlFor='phone_field'>Phone No</label>
              <input
                type='phone'
                id='phone_field'
                className='form-control'
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                required
              />
            </div>

            <div className='form-group'>
              <label htmlFor='postal_code_field'>Postal Code</label>
              <input
                type='number'
                id='postal_code_field'
                className='form-control'
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                required
              />
            </div>

            {/* <div className='form-group'>
              <label htmlFor='country_field'>Country</label>
              <select
                id='country_field'
                className='form-control'
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                required
              >
                {countriesList.map((country) => (
                  <option key={country.name} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div> */}

            <button
              id='shipping_btn'
              type='submit'
              className='btn btn-block py-3'
            >
              CONTINUE
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Shipping;
